import React from "react"
import { Link as GatsbyLink, StaticQuery, graphql } from "gatsby"
// import { MDXRenderer } from "gatsby-plugin-mdx"
// import { MDXProvider } from "@mdx-js/react"
import ReactMarkdown from "react-markdown"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import {
  Toolbar,
  Breadcrumbs,
  Link,
  Typography,
  Box,
  Container,
} from "@material-ui/core"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism"
import rehypeRaw from "rehype-raw"

import Layout from "../components/layout"

export const query = graphql`
  query CasestudyQuery($id: Int!) {
    strapiCasestudy(strapiId: { eq: $id }) {
      title
      description
      keywordsMeta
      content
      featured_image {
        publicURL
      }
      tags {
        name
      }
      created_at
    }
  }
`

const StyledToolbar = withStyles({
  root: {
    background: "#031242",
  },
})(Toolbar)

function Blog({ data }) {
  const casestudy = data.strapiCasestudy
  return (
    <Layout
      siteTitle={casestudy.title}
      description={casestudy.description}
      keywords={casestudy.keywordsMeta}
      ogImage={casestudy.featured_image?.publicURL}
    >
      <StyledToolbar />

      <Container>
        <Box pt={5}>
          <Box display="flex" justifyContent="flex-end">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" component={GatsbyLink} to="/">
                Home
              </Link>
              <Link color="inherit" component={GatsbyLink} to="/case-studies/">
                Case Studies
              </Link>
              <Typography color="textPrimary">
                {casestudy.title.substring(0, 20).concat("...")}
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box py={5}>
          <Typography variant="h4" gutterBottom color="primary" align="center">
            <b>{casestudy.title}</b>
          </Typography>
          <div>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <AccessTimeIcon fontSize="small" style={{ paddingRight: 5 }} />
              {new Date(casestudy.created_at).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Typography>

            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={casestudy.content}
              transformImageUri={uri =>
                uri.startsWith("http")
                  ? uri
                  : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
              }
              className="dynamicContent"
              escapeHtml={false}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "")
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, "")}
                      style={atomDark}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  )
                },
              }}
            />
          </div>
        </Box>
      </Container>
    </Layout>
  )
}

export default Blog
